//import RequireAuth from '../Components/RequireAuth';
import {Routes, Route} from 'react-router-dom';
import ViewAlum from './Alum/AlumProfiles';
// import AddAlum from './Alum/AddAlum';
import Alumus from './Alum/Alumus';

function Alum ({img}) {
  return (
    <Routes>
      <Route exact path='/' element={<ViewAlum img={img}/>} />
      <Route exact path='/view/:id' element={<Alumus img={img}/>} />       
      {/* <Route element={<RequireAuth allowedRoles={[3, 4, 5]} />}>
        <Route exact path='/add' element={<AddAlum img={img} cat={cat}/>}/>
      </Route> */}
    </Routes>
  )
}

export default Alum