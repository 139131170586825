import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import {Link} from 'react-router-dom';
import {useState, useEffect} from 'react';

const VIEW_TOPCONTENT_URL = '/news/top';

function RightSideBar({img}) {
    const axiosPrivate = useAxiosPrivate();
    const [topNews, setTopNews] = useState([]);
    const [topBlogs, setTopBlogs] = useState([]);
    const [nextEvents, setNextEvents] = useState([]);
    const month = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"];
    
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_TOPCONTENT_URL).then((response) => {
            if(isMounted){
                setTopNews(response.data.news);
                setTopBlogs(response.data.blogs);
                setNextEvents(response.data.events);
            } 
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort(); }
        // eslint-disable-next-line
    }, []);
 
    return (
        <div className="col-lg-4">
            <div className="saidbar">
                <div className="row">
                    <div className="col-lg-12 col-md-6">
                        <div className="saidbar-search mt-30">
                            <form action="#">
                                <input type="text" placeholder="Search" />
                                <button type="button"><i className="fa fa-search"></i></button>
                            </form>
                        </div> { /* <!-- saidbar search --> */ }
                    </div> { /* <!-- categories --> */ }
                    <div className="col-lg-12 col-md-6">
                        {topNews.length>0 && 
                            <div className="saidbar-post mt-30">
                                <h4>Trending News</h4>
                                    <ul>
                                    {topNews.map((news, index)=>{
                                        return(
                                            <li key={news.id}>
                                                <div className="singel-post">
                                                    <div className='row'>
                                                        <div className='col-sm-4'>
                                                            <div className='thum mt-30'>
                                                                <img src={img + news.picture.split(',')[0]} alt="Blog" />
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-8'>
                                                            <div className="cont">
                                                                <Link to={`/news/view/${news.id}`}><h6>{news.title}</h6></Link>
                                                                <span>{new Date(news.createdAt).toDateString()}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> { /* <!-- singel post --> */ }
                                            </li>
                                        )
                                    })}
                                    </ul>
                            </div>  //* <!-- Sidebar Popular posts --> */ 
                        }
                        {   topBlogs.length>0 &&
                            <div className="saidbar-post mt-30">
                                <h4>Latest Blog Posts</h4>
                                <ul>
                                    {topBlogs.map((blog, index)=>{
                                        return(
                                            <li key={blog.id}>
                                                <div className="singel-post">
                                                    <div className='row'>
                                                        <div className='col-sm-4'>
                                                            <div className='thum mt-30'>
                                                                <img src={img + blog.picture.split(',')[0]} alt="Blog" />
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-8'>
                                                            <div className="cont">
                                                                <Link to={`/blog/view/${blog.id}`}><h6>{blog.title}</h6></Link>
                                                                <span>{new Date(blog.createdAt).toDateString()}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> { /* <!-- singel post --> */ }
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>  //* <!-- Sidebar Latest posts --> */ 
                        }
                        {   nextEvents.length>0 &&
                            <div className="saidbar-post mt-30">
                                <h4>Upcoming Events</h4>
                                <ul>
                                    {nextEvents.map((event, index)=>{
                                        return(
                                            <div key={event.id} className="singel-feature mt-30">
                                            <div className='cont'>
                                                <div className="row">
                                                    <div className='col-4 pt-1 events-address'>
                                                        <p className='px-3'>{ month[new Date(event.date).getMonth()]}</p>
                                                        {(new Date(event.date).getDate())>9 ? 
                                                            <p className='date-h2'> {new Date(event.date).getDate()}</p>
                                                        :
                                                            <p className='date-h2'> 0{new Date(event.date).getDate()}</p>
                                                        }
                                                        <p className='px-2'>{ new Date(event.date).getFullYear()}</p>
                                                    </div>
                                                    <div className="col-8 pt-2">
                                                        <Link to={`/events/view/${event.id}`}><h6>{event.title}</h6></Link><br />
                                                        {event.location && <p><i className="fa fa-map-marker"></i> {event.location}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    })}
                                </ul>
                            </div>  //* <!-- Sidebar Latest posts --> */ 
                        }
                    </div>
                </div> { /* <!-- row --> */ }
            </div> { /* <!-- saidbar --> */ }
        </div>
    )
}

export default RightSideBar