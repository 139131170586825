import { Route, Routes} from 'react-router-dom';
import PageNotFound from './PageNotFound'
import Page from './Page';

function Pages({img} ) {  
    return (
        <Routes>
            <Route exact path='/about/*' element={<Page id={'about'} img={img}/>} />
            <Route exact path='/principal/*' element={<Page id={'principal'} img={img}/>} />
            <Route exact path='/director/*' element={<Page id={'director'} img={img}/>} />
            <Route exact path='/academic/*' element={<Page id={'academic'} img={img}/>} />
            <Route exact path='/arts/*' element={<Page id={'arts'} img={img}/>} />
            <Route exact path='/sport/*' element={<Page id={'sport'}  img={img}/>}/>
            <Route exact path='/leadership/*' element={<Page id={'leadership'} img={img}/>} />
            <Route exact path='/admissions/*' element={<Page id={'admissions'} img={img}/>} />
            <Route exact path='/fees/*' element={<Page id={'fees'} img={img}/>} />
            <Route exact path='/vacancies/*' element={<Page id={'vacancies'} img={img}/>} />
            <Route exact path='/support/*' element={<Page id={'support'} img={img}/>} />
            <Route exact path='/visit/*' element={<Page id={'visit'} img={img}/>} />
            <Route exact path='/sports/*' element={<Page id={'sports'} img={img}/>} />
            <Route exact path='/music/*' element={<Page id={'music'} img={img}/>} />
            <Route exact path='/language/*' element={<Page id={'language'} img={img}/>} />
            <Route exact path='/adventure/*' element={<Page id={'adventure'} img={img}/>} />
            <Route exact path='/religion/*' element={<Page id={'religion'} img={img}/>} />
            <Route exact path='/talent/*' element={<Page id={'talent'}  img={img}/>}/>
            <Route exact path='/kitante/*' element={<Page id={'kitante'} img={img}/>} />
            <Route exact path='/najja/*' element={<Page id={'najja'} img={img}/>} />
            <Route exact path='/makindye/*' element={<Page id={'makindye'} img={img}/>} />
            <Route exact path='/bunga/*' element={<Page id={'bunga'} img={img}/>} />
            <Route exact path='/ntinda/*' element={<Page id={'ntinda'}  img={img}/>}/>
            <Route exact path='/*' element={<PageNotFound img={img}/>} />
      </Routes>
    )
}

export default Pages