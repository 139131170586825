import RightSideBar from '../../Components/RightSideBar';
import {Link, useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';

//Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import NewsPageBanner from './NewsPageBanner';

const DELETE_STORY_URL = '/news/delete/';
const UPLOAD_FILE_URL = '/singleUpload';
const DELETE_FILE_URL = '/fileDelete/';
const EDIT_STORY_URL = '/news/edit/';
const VIEW_STORY_URL = '/news/view/';

function Story({img}) {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [selectedImages, setSelectedImages] = useState([]);
    const [updateMode, setUpdateMode] = useState(false);
    const [initialPics, setInitialPics] = useState([]);
    const [newsStory, setNewsStory] = useState({});  
    const [storyDate, setStoryDate] = useState('');
    const [pictures, setPictures] = useState([]);
    const [detail, setDetail] = useState('');
    const [title, setTitle] = useState('');
    const navigate = useNavigate();
    let {id} = useParams();
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_STORY_URL + id).then((response)=>{
            if(isMounted){
                setNewsStory(response.data);  
                setTitle(response.data.story.title);
                setDetail(response.data.story.detail);
                setPictures(response.data.story.picture.split(','));
                setInitialPics(response.data.story.picture.split(','));
                setStoryDate(response.data.story.createdAt.split('T')[0]);
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [id]);

    function deletePictures(pic) {
        setPictures(pictures.filter((e) => e !== pic));
    }
    
    const onSelectFile = (e) => {
        const selectedFiles = e.target.files;    
        const selectedFilesArray = Array.from(selectedFiles);
        
        const imagesFiltered = [];
    
        for(let i=0; i<selectedFilesArray.length; i++){
          if(selectedFilesArray[i].name.match(/\.(JPG|jpg|jpeg|JPEG|png|PNG)$/) && selectedFilesArray[i].size < 700000){
            imagesFiltered.push(selectedFilesArray[i]);
          }
        }

        const imagesArray = imagesFiltered.map((file) => {
          return ( {"image": URL.createObjectURL(file), "file": file } )
        });
        
        setSelectedImages((previousImages) => previousImages.concat(imagesArray));
        
        // FOR BUG IN CHROME
        e.target.value = "";
    };
    
    function deleteHandler(image) {
        setSelectedImages(selectedImages.filter((e) => e !== image));   
        URL.revokeObjectURL(image.image);
    }

    const deletePost = async(id) => {
        try {
            for(let i=0;i<pictures.length;i++){
                axiosPrivate.post(DELETE_FILE_URL + pictures[i]).then(()=>{
                    console.log('Old image deleted');
                }).catch(function(error){
                    console.log(error.config);
                });
            }

            axiosPrivate.delete(DELETE_STORY_URL + id).then(()=>{
                alert('News Story Deleted');
                navigate('/news');
            }).catch(function(error){
                console.log(error.config);
            });               
        }catch(error) {
            console.log(error);
        }
    };

    const onDetail = (value) => {
        setDetail(value);
    } 

    const updatePost = async(e) => {
        e.preventDefault();

        let picture = '';
        
        if(pictures.length>0){
            picture = pictures[0];
            for(let i=1; i<pictures.length; i++) {
                picture = picture + ',' + pictures[i];
            }
        }
        
        if(selectedImages.length>0 && pictures.length<3){
            let numberOfnewPics = 3 - pictures.length;
            
            for(let i=0; i<numberOfnewPics && i<selectedImages.length; i++) {
                const formData = new FormData();
                const filename = Date.now()+ ' - ' + selectedImages[i].file.name;       
                formData.append("name", filename);
                formData.append("file", selectedImages[i].file);
                
                try{
                    await axiosPrivate.post(UPLOAD_FILE_URL, formData);   
                }catch (error) {
                    console.log(error);  
                }
                
                if(i===0){
                    picture = filename;
                }else {
                    picture = picture + ',' + filename;
                }
            }
        }
        
        if(!pictures && !selectedImages){
            picture = newsStory.story.picture;
        }

        for(let i=0; i<initialPics.length; i++){
            if(!picture.includes(initialPics[i])){
                axiosPrivate.post(DELETE_FILE_URL + initialPics[i]).then(()=>{
                    console.log('Old image deleted');
                }).catch(function(error){
                    console.log(error.config);
                });
            }
        }

        const updatedStory = {title, detail, picture, userId: authDecode.user.id, id: newsStory.story.id, createdAt: storyDate};
       
        try {
            axiosPrivate.put(EDIT_STORY_URL + newsStory.story.id, updatedStory).then(()=>{
                alert('News Story Edited');
                setUpdateMode(false);
                window.location.reload();
            });   
        }catch (error) {
            console.log(error);  
        }
    }

    return (
        <>
            <NewsPageBanner PT={newsStory?.story?.title} img={img}/>
            <section className="pb-30 gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="blog-details mt-30">
                                { updateMode ?
                                    <div className='main-form pt-45'> 
                                        <form className="p-2">
                                            <div className='row form-group section-title'>
                                                <div className="col-lg-8">
                                                    <br /><h5>Title</h5> 
                                                    <input className="form-control p-2" required="required" type='text' value={title} onChange={(e)=>setTitle(e.target.value)} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <br /><h5>Date</h5> 
                                                    <input className="form-control p-2" required="required" type='date' value={storyDate} onChange={(e)=>setStoryDate(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-lg-8 form-group section-title">
                                                    <br /><h5>Story Details</h5>
                                                    <ReactQuill theme="snow" rows="20"  value={detail} onChange={onDetail} />
                                                </div>
                                                <div className='col-lg-4'>
                                                    <div className="section-title form-group">
                                                        <br /><h5>Pictures:</h5>
                                                    </div>                            
                                                    { pictures.map((pic, idx) =>{
                                                        return(
                                                            <div key={idx}>
                                                                <img src={img + pic} alt={pic} className="img-fluid w-100" />
                                                                <i className="fa fa-trash" aria-hidden="true" onClick={() => deletePictures(pic)}/>
                                                                <br /><br />
                                                            </div>
                                                        )
                                                    })}

                                                    { selectedImages.length > 0 && selectedImages.map((image) => { 
                                                        return (
                                                            <div key={image.image}>
                                                                <img src={image.image} alt="upload" className="img-fluid w-100" /><br />
                                                                <i className="fa fa-trash" aria-hidden="true" onClick={() => deleteHandler(image)}/>
                                                            </div>
                                                        );
                                                    })}
                                                    <label htmlFor="fileInput">
                                                        <h5><i className="fa fa-recycle"></i> Change Pictures</h5>
                                                        <input id="fileInput" type="file" name="files" style={{display:'none'}} onChange={(e) => onSelectFile(e)} multiple accept="image/png, image/jpeg, image/jpg" />
                                                    </label>
                                                    <br/><br/>
                                                    <p><i className='fa fa-warning' style={{color: 'red', fontSize: '1.5rem'}}/>&nbsp;&nbsp;Only jpg, jpeg and png files of not more than 700kb will be uploaded</p>
                                                    <br/><br/>                                                    
                                                </div>
                                            </div>
                                            <br />
                                            <button type="submit"className="main-btn" onClick={updatePost}>Update</button>
                                            &nbsp;&nbsp;&nbsp;  
                                            <button className="main-btn" onClick={(e)=>setUpdateMode(false)}>Cancel</button>
                                        </form>
                                    </div> 
                                : 
                                    <>
                                        {pictures && <img src={img + pictures[0]} alt="" style={{height: '500px', objectFit: 'cover'}} />}
                                        <div className="cont">
                                            <h3 className="mb-3 text-secondary text-capitalize font-weight-bold">{title}</h3>
                                            <ul>
                                                <li><Link to="#date"><i className="fa fa-calendar"></i>{new Date(newsStory?.story?.createdAt).toDateString()}</Link></li>
                                                { (authDecode && (authDecode?.user?.id === newsStory?.story?.userId || authDecode?.user?.role >= 4)) && 
                                                    <>
                                                        <li><Link to="#edit"><i className="fa fa-edit" aria-hidden="true" onClick={() => setUpdateMode(true)}/></Link>Edit</li>
                                                        <li><Link to="#delete"><i className="fa fa-trash" aria-hidden="true" onClick={() => deletePost(newsStory.story.id)} /></Link>Delete</li>
                                                    </>
                                                }
                                            </ul> 
                                            <p className="mt-1 p-3" dangerouslySetInnerHTML={{ __html: newsStory.textBatch1}} style={{whiteSpace: "pre-line"}} />
                                            { newsStory.textBatch2 &&
                                                <> 
                                                    { pictures.length>1 && <div className='pages-image-left mb-3'><img src={img + pictures[1]} alt={title} className="img-fluid w-100" style={{height: '400px', objectFit: 'cover'}}/></div> }
                                                    <p dangerouslySetInnerHTML={{ __html: newsStory.textBatch2}} style={{whiteSpace: "pre-line"}} />
                                                </>
                                            }          
                                            { newsStory.textBatch3 && 
                                                <>
                                                { pictures.length>2 && <div className='mt-3 mb-3'><img src={img + pictures[2]} alt={title} className="img-fluid w-100" style={{height: '400px', objectFit: 'cover'}}/></div>}
                                                <p dangerouslySetInnerHTML={{ __html: newsStory.textBatch3}} style={{whiteSpace: "pre-line"}} />
                                                </>
                                            } 
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <RightSideBar img={img}/>  
                    </div>
                </div>
            </section>
        </>
    )
}

export default Story